import React, { useEffect, useState, useRef } from "react";
import IMG from "./IMG";
import { FiX } from "react-icons/fi";

const Modalreport = ({ isOpen, onClose, report }) => {
  const [isFullScreenImage, setIsFullScreenImage] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    const handleKeyPress = (e) => {
      if (e.key === "Escape" && !isFullScreenImage) {
        onClose();
      }
    };

    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    window.addEventListener("keydown", handleKeyPress);
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen, isFullScreenImage, onClose]);

  if (!isOpen || !report) return null;

  const fieldLabels = report.responses.find(r => r.key === "fieldLabels")?.value || {};

  const renderField = (key, value) => {
    if (key.endsWith("Observation") && value === "-") return null;

    const label = fieldLabels[key] || key;

    if (typeof value === "boolean" || value === "true" || value === "false") {
      return (
        <p key={key} className="text-sm">
          <strong className="text-gray-600">- {label}:</strong>{" "}
          <span className={value === "true" ? "text-green-600" : "text-red-600"}>
            {value === "true" ? "Sí" : "No"}
          </span>
        </p>
      );
    }

    if (typeof value === "string" && key.endsWith("Observation")) {
      return (
        <p key={key} className="text-sm">
          <strong className="text-gray-600">- {label}:</strong>{" "}
          <span className="text-orange-500">{value}</span>
        </p>
      );
    }

    if (typeof value === "string" && key === "timestamp") {
      return null;
    }

    return (
      <p key={key} className="text-sm">
        <strong className="text-gray-600">- {label}:</strong> {value}
      </p>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 -top-6">
      <div ref={modalRef} className="bg-white rounded-lg p-6 shadow-lg w-full max-w-2xl max-h-[96vh] m-4 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FiX size={24} />
        </button>

        <div>
          <h2 className="text-xl font-semibold mb-0">
            {report.form_id || "Detalles del Reporte"}
          </h2>
          <div className="bg-white shadow rounded-lg space-y-2">
            <div className="space-y-2">
              <p className="text-lg font-semibold text-gray-800">
                Información del Reporte
              </p>
              <div className="bg-white rounded-lg shadow-lg w-full max-h-[45vh] overflow-y-auto m-4 pb-6">
                {report.responses.map(({ key, value }) =>
                  renderField(key, value)
                )}
              </div>
            </div>

            <div className="space-y-2">
              <p className="text-lg font-semibold text-gray-800">
                Imágenes del Reporte
              </p>
              <div className="flex flex-wrap gap-2">
                {report.image_paths && report.image_paths.length > 0 ? (
                  report.image_paths.map((imagePath, imgIndex) => (
                    <IMG
                      key={imgIndex}
                      keya={imgIndex}
                      className="w-20 h-20 rounded object-cover"
                      src={`https://offline.smartaccesorios.shop/.phpOffSSOMA${imagePath.substring(1)}`}
                      alt={`Imagen ${imgIndex + 1}`}
                      onFullScreenChange={setIsFullScreenImage}
                    />
                  ))
                ) : (
                  <p className="text-gray-500">No hay imágenes disponibles.</p>
                )}
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modalreport;