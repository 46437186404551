import React from "react";
import { FiEye } from "react-icons/fi";

const DailyActivitiesList = ({
  activities,
  usersios,
  maquineries,
  onEditActivity,
}) => {
  

  const getMachineryName = (id) => {
    const machinery = maquineries.find((m) => m.id === Number(id));
    return machinery ? machinery.nombre : "Desconocido";
  };

  const getUserName = (id) => {
    const user = usersios.find((u) => u.id === Number(id));
    return user
      ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
      : "Desconocido2";
  };

  const getUserNameDNI = (dni) => {
    const user = usersios.find((u) => Number(u.dni) === Number(dni));
    return user
      ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}`
      : "Desconocido2";
  };

  const extractName = (data) => {
    const [id, timestamp] = data.split("_"); // Extraer id y timestamp
    const userName = getUserNameDNI(Number(id)); // Obtener el nombre completo
    return `${userName} - ${timestamp}`; // Devolver el resultado
  };

  const renderActivityCard = (activity) => (
    <div
      key={activity.id}
      className="p-4 bg-white rounded-lg shadow-xl drop-shadow-xl flex items-center mb-4"
    >
      <div className="flex-1 flex flex-col justify-between">
        <div>
          <h3 className="text-sm font-semibold">
            {getMachineryName(activity.machineryId)}
          </h3>
          <p className="text-xs text-gray-400">
            Enviado por:&nbsp;{extractName(activity["uniqueId "])}
          </p>
          <p className="text-xs text-gray-400">
            Operador: {getUserName(activity.operatorId)}
          </p>
          <p className="text-xs text-gray-400">
            Asistentes:{" "}
            {activity.assistants
              ? activity.assistants.split(",").map(getUserName).join(", ")
              : "Ninguno"}
          </p>
        </div>
        <div className="flex justify-between items-center mt-2 px-10">
          <button
            onClick={() => onEditActivity(activity)}
            className="text-green-500 hover:text-green-700 flex flex-row justify-center items-center text-xs"
          >
            Ver &nbsp; <FiEye className="h-5 w-5" />
          </button>
          <div className="text-xs flex flex-row justify-center items-center ">
            Estado:&nbsp;
            <p
              className={`${
                activity.approvalStatus === 0
                  ? "text-yellow-500" // PENDIENTE
                  : activity.approvalStatus === 1
                  ? "text-green-500" // APROBADO
                  : activity.approvalStatus === 2
                  ? "text-red-500" // DESAPROBADO
                  : "text-gray-500" // Desconocido
              }`}
            >
              {`${
                activity.approvalStatus === 0
                  ? "PENDIENTE"
                  : activity.approvalStatus === 1
                  ? "APROBADO"
                  : activity.approvalStatus === 2
                  ? "DESAPROBADO"
                  : "Desconocido"
              }` || "Pendiente"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {activities.length > 0 ? (
        activities
          .sort((a, b) => a.approvalStatus - b.approvalStatus)
          .map(renderActivityCard)
      ) : (
        <p>No hay actividades registradas.</p>
      )}
    </div>
  );
};

export default DailyActivitiesList;
