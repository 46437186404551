import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Loader from "./ayuda/Loader";
import { FaEdit, FaSearch, FaTrash } from "react-icons/fa";

const Modal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("usuarios");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const tabs = [
    { name: "Usuarios", type: "usuarios" },
    { name: "Roles", type: "roles" },
    { name: "Maquinarias", type: "maquinarias" },
  ];

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 overflow-y-auto h-full w-full z-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div
        className="relative mx-auto w-full max-w-7xl h-[100vh] md:h-[60vh] shadow-2xl rounded-lg bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-6 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-t-lg">
          <div className="flex space-x-6">
            {tabs.map((tab) => (
              <button
                key={tab.type}
                onClick={() => setActiveTab(tab.type)}
                className={`text-sm font-semibold text-white ${
                  activeTab === tab.type
                    ? "border-b-2 border-white"
                    : "opacity-70 hover:opacity-100"
                }`}
              >
                {tab.name}
              </button>
            ))}
          </div>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200 focus:outline-none"
          >
            ✕
          </button>
        </div>
        <div className="p-6 h-[91vh] overflow-y-auto">
          <Form type={activeTab} />
        </div>
      </div>
    </div>
  );
};

const Form = ({ type }) => {
  const [formData, setFormData] = useState({});
  const [listData, setListData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://offline.smartaccesorios.shop/.phpOffSSOMA/api/index.php";
  const apiDni = "https://offline.smartaccesorios.shop/.phpDNIs/consultaDoc.php?tipoDocumento=dni&numeroDocumento=";

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${apiUrl}?route=${type}`);
      setListData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error al cargar los datos. Por favor, intente nuevamente.");
    } finally {
      setLoading(false);
    }
  }, [type, apiUrl]);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}?route=roles`);
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setError("Error al cargar los roles. Por favor, intente nuevamente.");
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchData();
    if (type === "usuarios") {
      fetchRoles();
    }
    // Reset form state when changing tabs
    setFormData({});
    setIsEditing(false);
  }, [type, fetchData, fetchRoles]);

  const fetchDNI = async (dni) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${apiDni}${dni}`);
      setFormData({ 
        ...formData, 
        nombres: response.data.Nombres, 
        apPaterno: response.data.ApellidoPaterno, 
        apMaterno: response.data.ApellidoMaterno, 
        email: response.data.Correo 
      });
    } catch (error) {
      console.error("Error fetching DNI data:", error);
      setError("Error al buscar el DNI. Por favor, verifique el número e intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (isEditing) {
        await axios.put(`${apiUrl}?route=${type}`, formData);
      } else {
        await axios.post(`${apiUrl}?route=${type}`, formData);
      }
      fetchData();
      setFormData({});
      setIsEditing(false);
      alert(isEditing ? "Datos actualizados con éxito" : "Datos creados con éxito");
    } catch (error) {
      console.error("Error saving data:", error);
      setError("Error al guardar los datos. Por favor, intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (item) => {
    setFormData(item);
    setIsEditing(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("¿Está seguro de que desea eliminar este elemento?")) {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`${apiUrl}?route=${type}&id=${id}`);
        fetchData();
        alert("Elemento eliminado con éxito");
      } catch (error) {
        console.error("Error deleting data:", error);
        setError("Error al eliminar el elemento. Por favor, intente nuevamente.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearchDNI = async (e) => {
    e.preventDefault();
    const dni = formData.dni;
    if (dni && dni.length === 8) {
      fetchDNI(dni);
    } else {
      alert("El número de DNI debe tener 8 dígitos, sin espacios antes o después.");
    }
  };

  const renderForm = () => {
    const fields = {
      usuarios: [
        { name: "dni", type: "text", placeholder: "DNI" },
        { name: "nombres", type: "text", placeholder: "Nombres" },
        { name: "apPaterno", type: "text", placeholder: "Ap Paterno" },
        { name: "apMaterno", type: "text", placeholder: "Ap Materno" },
        { name: "email", type: "email", placeholder: "Email" },
        { name: "rol_id", type: "select", placeholder: "Rol", options: roles },
      ],
      roles: [{ name: "nombre", type: "text", placeholder: "Nombre del Rol" }],
      maquinarias: [
        { name: "nombre", type: "text", placeholder: "Nombre" },
        { name: "codigo", type: "text", placeholder: "Codigo" },
        { name: "modelo", type: "text", placeholder: "Modelo" },
        { name: "serie", type: "text", placeholder: "Serie" },
        { name: "tipoCombustible", type: "text", placeholder: "Tipo De Combustible" },
        {
          name: "estado",
          type: "select",
          placeholder: "Estado",
          options: ["Activo", "Inactivo", "En mantenimiento"],
        },
      ],
    };

    return (
      <form onSubmit={handleSubmit} className="bg-black/5 px-6 py-2 h-80 rounded-xl grid grid-cols-2 justify-center items-center gap-2">
        {fields[type].map((field) => (
          <div key={field.name}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {field.placeholder}
            </label>
            {field.type === "select" ? (
              <select
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleChange}
                className="block w-full py-1 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
              >
                <option value="">Seleccione...</option>
                {field.options.map((option) => (
                  <option key={option.id || option} value={option.id || option}>
                    {option.nombre || option}
                  </option>
                ))}
              </select>
            ) : (
              <div className="flex items-center">
                <input
                  autoComplete="off"
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  className="mt-1 focus:ring-blue-500 py-0.5 pl-2 focus:border-blue-500 block w-full shadow-sm text-sm border-gray-300 rounded-md"
                />
                {field.name === "dni" && (
                  <button 
                    type="button"
                    className="relative z-50 right-6 p-2 bg-amber-400 rounded-full"
                    onClick={handleSearchDNI}
                  >
                    <FaSearch />
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
        
        <div className="flex col-span-2">
          <button
            type="submit"
            className="mt-5 relative w-full h-full z-50 bottom-0 right-0 bg-red-500 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            {isEditing ? "Actualizar" : "Crear"}
          </button>
        </div>
      </form>
    );
  };

  const renderList = () => {
    return (
      <div className="md:h-96 h-full rounded-2xl p-2 md:p-6 bg-black/10">
        <h3 className="text-lg font-semibold leading-6 text-gray-800 mb-4">
          Lista de {type}
        </h3>
        {loading ? (
          <Loader />
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div className="flex overflow-y-auto h-72 w-full">
            <ul className="w-full">
              {listData.map((item, i) => (
                <li
                  key={item.id}
                  className={`${
                    i % 2 === 0 ? "bg-white" : "bg-slate-300"
                  } w-full flex items-center justify-between rounded-xl p-2 md:p-4`}
                >
                  <div>
                    {type === "usuarios" && (
                      <>
                        <p className="text-sm font-medium text-gray-900 flex flex-row">
                          {item.nombres} {item.apPaterno}&nbsp;<span className="hidden md:block">{item.apMaterno}</span>
                        </p>
                        <p className="text-xs md:text-sm text-gray-500">
                          {item.rol} --- {item.email}
                        </p>
                      </>
                    )}
                    {type === "maquinarias" && (
                      <>
                        <p className="text-sm font-medium text-gray-900">
                          {item.nombre}
                        </p>
                        <p className="text-xs md:text-sm text-gray-500">
                          {item.tipo}
                        </p>
                      </>
                    )}
                    {type === "roles" && (
                      <p className="text-sm font-medium text-gray-900">
                        {item.nombre}
                      </p>
                    )}
                  </div>
                  <div className="ml-2 mx-2 flex space-x-2">
                    <button
                      onClick={() => handleEdit(item)}
                      className="bg-yellow-500 text-white px-2 py-1 rounded-full text-xs font-medium hover:bg-yellow-600"
                    >
                      <FaEdit />
                    </button>
                    {type !== "usuarios" && (
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="bg-red-500 text-white px-2 py-1 rounded-full text-xs font-medium hover:bg-red-600"
                      >
                        <FaTrash />
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 max-h-[87vh] overflow-hidden">
      <div className="md:w-1/2">
        <h2
          className={`${
            type === "usuarios"
              ? "bg-slate-500"
              : type === "roles"
              ? "bg-gray-500"
              : "bg-neutral-500"
          } text-xl font-semibold leading-6 text-white mb-6 text-center py-2 rounded-xl`}
        >
          {isEditing ? "Editar" : "Crear"} {type}
        </h2>
        {renderForm()}
      </div>
      <div className="md:w-1/2 overflow-y-auto h-[50vh] md:h-96">{renderList()}</div>
    </div>
  );
};

export { Modal };