// src/components/UpdatePrompt.js
import React from 'react';

function UpdatePrompt({ onUpdate, onCancel }) {
  return (
    <div className="update-prompt">
      <p>Hay una nueva versión disponible. ¿Deseas actualizar?</p>
      <button onClick={onUpdate}>Actualizar</button>
      <button onClick={onCancel}>Cancelar</button>
    </div>
  );
}

export default UpdatePrompt;