import React, { useState, useEffect } from 'react';
import DailyActivitiesModal from './DailyActivitiesModal';
import DailyActivitiesList from './DailyActivitiesList';
import {
  getAllDailyActivities,
  updateDailyActivitySyncStatus, deleteDailyActivity
} from '../../idb';
import { FiPlus } from 'react-icons/fi';
import syncActivities from '../../syncActivities'; // Importa la función de sincronización

const DailyActivitiesDashboard = ({ usersios, maquineries }) => {
  const [activities, setActivities] = useState([]);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => setIsOnline(navigator.onLine);
    
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    const storedActivities = await getAllDailyActivities();
    setActivities(storedActivities);
  };

  const handleEditActivity = (activity) => {
    setSelectedActivityId(activity.id);
    setIsModalOpen(true);
  };

  const handleNewActivity = () => {    
    setSelectedActivityId(null);
    setIsModalOpen(true);
  };

  const handleDeleteActivity = async (id) => {
    await deleteDailyActivity(id);
    fetchActivities();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedActivityId(null);
    fetchActivities(); // Actualiza la lista de actividades después de cerrar el modal
  };

  const handleSyncActivity = async (activityId) => {
    setIsSyncing(true);
    try {
      await syncActivities();
      await updateDailyActivitySyncStatus(activityId, true);
      fetchActivities(); // Actualiza la lista de actividades después de la sincronización
    } catch (error) {
      console.error('Error al sincronizar la actividad:', error);
    } finally {
      setIsSyncing(false);
    }
  };

  const handleSyncAllActivities = async () => {
    setIsSyncing(true);
    try {
      await syncActivities();
      fetchActivities(); // Actualiza la lista de actividades después de la sincronización
    } catch (error) {
      console.error('Error al sincronizar todas las actividades:', error);
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Panel de Actividades Diarias</h1>
      <div className="flex justify-between mb-4">
        <button
          onClick={handleNewActivity}
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
        >
          <FiPlus className="mr-2" /> Nuevo Registro de Actividades
        </button>
        <>
        {isOnline && <button
          onClick={handleSyncAllActivities}
          className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
          disabled={isSyncing}
        >
          {isSyncing ? 'Sincronizando...' : 'Sincronizar Todas'}
        </button>}
        </>
      </div>
      <DailyActivitiesList 
        activities={activities} 
        onEditActivity={handleEditActivity} 
        onDeleteActivity={handleDeleteActivity}
        onSyncActivity={handleSyncActivity}
        isSyncing={isSyncing}        
        usersios={usersios}
        maquineries={maquineries}
      />
      <DailyActivitiesModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        activityId={selectedActivityId}
        usersios={usersios}
        maquineries={maquineries}
      />
    </div>
  );
};

export default DailyActivitiesDashboard;