import React, { useState, useEffect } from "react";
import ImageUploader from "./ImageUploader";
import { InformationCircleIcon, PlusIcon } from "@heroicons/react/16/solid";

const GenericForm = ({ fields, onSubmit, title, initialFormData = {} }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [images, setImages] = useState([]);
  const [showObservations, setShowObservations] = useState({});
  const [errorFields, setErrorFields] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (Object.keys(formData).length === 0) {
      const initialData = fields.reduce((acc, field) => {
        if (field.type === 'checkbox') {
          acc[field.name] = false;
        } else if (field.type === 'rating') {
          acc[field.name] = '';
        } else {
          acc[field.name] = '';
        }
        if (field.observation) {
          acc[`${field.name}Observation`] = '-';
        }
        return acc;
      }, {});
      setFormData(initialData);
    }
  }, []);

  useEffect(() => {
    setFormData(prevData => ({ ...prevData, ...initialFormData }));
  }, [initialFormData]);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const emptyRatingFields = fields
      .filter((field, index) => field.type === 'rating' && formData[field.name] === '')
      .map((field, index) => ({
        label: field.label,
        number: index + 1
      }));

    if (emptyRatingFields.length > 0) {
      setErrorFields(emptyRatingFields);
      setShowErrorModal(true);
    } else {
      onSubmit({ ...formData, images });
    }
  };

  const toggleObservation = (fieldName) => {
    setShowObservations((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
    if (showObservations[fieldName]) {
      handleInputChange(`${fieldName}Observation`, '-');
    }
  };

  const renderField = (field, index) => {
    const totalFields = fields.length;
    const currentFieldNumber = index + 1;
    const fieldContent = (
      <div className="flex flex-col space-y-2 w-full">
        <label className="text-sm font-medium text-gray-800 flex items-center">
          <span className="flex items-center justify-center bg-gray-400 rounded-full w-6 h-6 text-[8px] text-white absolute -m-5 -ml-7">
            {currentFieldNumber}/{totalFields}
          </span>
          {field.label}
          {field.tooltip && (
            <InformationCircleIcon
              className="w-4 h-4 ml-1 text-gray-400 cursor-help"
              title={field.tooltip}
            />
          )}
        </label>
        {field.type === "checkbox" && (
          <input
            type="checkbox"
            checked={formData[field.name] || false}
            onChange={(e) => handleInputChange(field.name, e.target.checked)}
            required={field.required}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        )}
        {field.type === "rating" && (
          <div className="flex space-x-2">
            {["Malo", "Regular", "Bueno"].map((option) => (
              <button
                key={option}
                type="button"
                onClick={() => handleInputChange(field.name, option)}
                className={`flex-1 py-2 rounded-lg focus:outline-none transition-colors duration-200 ${
                  formData[field.name] === option
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                {option}
              </button>
            ))}
          </div>
        )}
        {(field.type === "text" || field.type === "date") && (
          <input
            type={field.type}
            value={formData[field.name] || ""}
            onChange={(e) => handleInputChange(field.name, e.target.value)}
            placeholder={field.placeholder}
            required={field.required}
            className="w-full p-3 text-gray-900 bg-gray-100 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        )}
        {field.type === "textarea" && (
          <textarea
            value={formData[field.name] || ""}
            onChange={(e) => handleInputChange(field.name, e.target.value)}
            placeholder={field.placeholder}
            required={field.required}
            className="w-full p-3 text-gray-900 bg-gray-100 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          />
        )}

        {field.observation && (
          <button
            type="button"
            onClick={() => toggleObservation(field.name)}
            className="mt-2 text-sm text-blue-600 hover:underline focus:outline-none flex items-center space-x-1"
          >
            {showObservations[field.name] ? (
              "Ocultar observación"
            ) : (
              <>
                <PlusIcon className="h-4 w-4" />
                <span>Agregar observación</span>
              </>
            )}
          </button>
        )}
        {field.observation && showObservations[field.name] && (
          <div className="mt-2">
            <textarea
              value={formData[`${field.name}Observation`] || ""}
              onChange={(e) =>
                handleInputChange(`${field.name}Observation`, e.target.value)
              }
              placeholder="Escribe tu observación..."
              className="w-full p-3 text-gray-800 bg-gray-100 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        )}
      </div>
    );

    return (
      <div
        key={field.name}
        className="bg-white shadow-sm rounded-lg p-4 hover:shadow-md transition-shadow duration-200"
      >
        {fieldContent}
      </div>
    );
  };

  const ErrorModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h3 className="text-xl font-bold mb-4">Campos obligatorios</h3>
        <p>Por favor, complete los siguientes campos:</p>
        <ul className="list-disc pl-5 mt-2">
          {errorFields.map((field, index) => (
            <li key={index} className="text-red-600">
              {field.number}.- {field.label}
            </li>
          ))}
        </ul>
        <button
          onClick={() => setShowErrorModal(false)}
          className="mt-4 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
        >
          Cerrar
        </button>
      </div>
    </div>
  );

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8 ">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-xl font-extrabold text-center text-gray-900 mb-8">
          {title}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {fields.map((field, index) => renderField(field, index))}
          </div>

          <ImageUploader images={images} setImages={setImages} />

          <div className="flex justify-center">
            <button
              type="submit"
              className="py-3 px-6 bg-red-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
            >
              Guardar Reporte
            </button>
          </div>
        </form>
      </div>
      {showErrorModal && <ErrorModal />}
    </div>
  );
};

export default GenericForm;