import React, { useState, useEffect } from 'react';
import DailyActivitiesOnlineList from './DailyActivitiesOnlineList';
import DailyActivitiesOnlineModal from './DailyActivitiesOnlineModal';
import Loader from '../ayuda/Loader'

const DailyActivitiesDashboard = ({ usersios, maquineries, dni, isSuperAdmin }) => {
  const [activities, setActivities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [currentActivity, setCurrentActivity] = useState(null);

  const [loading, setLoading] = useState(false);

  

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      setLoading(true)
      const response = await fetch('https://offline.smartaccesorios.shop/.phpOffSSOMA/dailyActivities.php');
      const data = await response.json();
      setActivities(data.data);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handleEditActivity = (activity) => {
    setCurrentActivity(activity);
    setIsModalOpen(true);
  };

  const handleUpdateActivity = (updatedActivity) => {
    setActivities((prevActivities) =>
      prevActivities.map((activity) =>
        activity.id === updatedActivity.id ? updatedActivity : activity
      )
    );
  };

  if (loading) {
    return <Loader text="Cargando..." />;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Panel de Actividades Diarias</h1>
      <DailyActivitiesOnlineList 
        activities={activities} 
        usersios={usersios}
        maquineries={maquineries}
        onEditActivity={handleEditActivity} 
      />
      <DailyActivitiesOnlineModal
        isSuperAdmin={isSuperAdmin}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        activity={currentActivity}
        usersios={usersios}
        maquineries={maquineries}
        dni={dni}
        onUpdateActivity={handleUpdateActivity} // Pasar la función aquí
      />
    </div>
  );
};

export default DailyActivitiesDashboard;