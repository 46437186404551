import React, { useState, useEffect } from "react";
import { getOfflineReports, deleteReport } from "../idb";
import syncReports from "../syncReportes";
import AlertModal from "./ayuda/ModalReport";
import IMG from "./ayuda/IMG";
import { FiEye, FiSearch, FiTrash } from "react-icons/fi";

function ReportTable({ loader }) {
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showModal, setShowModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("all");

  useEffect(() => {
    async function fetchReports() {
      const storedReports = await getOfflineReports();
      setReports(storedReports);
      setFilteredReports(storedReports);
    }
    fetchReports();
  }, []);

  useEffect(() => {
    const handleOnlineStatus = () => setIsOnline(navigator.onLine);
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const filtered = reports.filter(report => 
      (filterType === "all" || report.title.includes(filterType)) &&
      Object.values(report).some(value => 
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredReports(filtered);
  }, [searchTerm, filterType, reports]);

  const handleSync = async () => {
    if (isOnline) {
      await syncReports(loader);
      const updatedReports = await getOfflineReports();
      setReports(updatedReports);
      setFilteredReports(updatedReports);
    } else {
      alert("No estás online. Conéctate para sincronizar los reportes.");
    }
  };

  const handleDelete = async (id) => {
    await deleteReport(id);
    const updatedReports = await getOfflineReports();
    setReports(updatedReports);
    setFilteredReports(updatedReports);
  };

  const confirmDelete = (report) => {
    setReportToDelete(report);
    setShowModal(true);
  };

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
    console.log(report)
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text || "";
  };

  const renderReportCard = (report) => (
    <div key={report.id} className="p-4 bg-white rounded-lg shadow-xl drop-shadow-xl flex items-center ">
      <div className="flex-shrink-0 mr-4">
        {report.images && report.images.length > 0 ? (
          <div>
            <IMG
              className="w-16 h-16 rounded-full object-cover"
              src={URL.createObjectURL(report.images[0])}
              alt="Imagen principal"
              keya={report.id}
            />
            <div className="flex space-x-1 mt-2">
              {report.images.slice(1, 4).map((image, imgIndex) => (
                <IMG
                  key={imgIndex}
                  keya={`${report.id}-${imgIndex}`}
                  className="w-5 h-5 rounded object-cover"
                  src={URL.createObjectURL(image)}
                  alt={`Imagen secundaria ${imgIndex + 1}`}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
            <span className="text-gray-500 text-xs text-center">Sin imágenes</span>
          </div>
        )}
      </div>
      <div className="flex-1 flex flex-col justify-between">
        <div>
          <h3 className="text-sm font-semibold">{truncateText(report.title, 80)}</h3>
          <p className="text-xs text-gray-400">{report.creacion}</p>
        </div>
        <div className="flex justify-between items-center mt-2">
          <div className="flex space-x-2">
            <button onClick={() => confirmDelete(report)} className="text-red-500 hover:text-red-700">
              <FiTrash className="h-5 w-5" />
            </button>
            <button onClick={() => handleViewReport(report)} className="text-green-500 hover:text-green-700">
              <FiEye className="h-5 w-5" />
            </button>
          </div>
          <div className={`w-3 h-3 rounded-full ${report.synced ? "bg-green-500" : "bg-red-500"}`}></div>
        </div>
      </div>
    </div>
  );
  
  return (
    <div className="mt-6 space-y-6 p-4 bg-gray-50 rounded-lg shadow-lg max-w-4xl mx-auto">
      <h2 className="text-2xl text-center font-bold text-gray-600 mb-6">Historial de Reportes</h2>
      
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
          <input
            type="text"
            placeholder="Buscar reportes..."
            className="w-full pl-10 pr-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        </div>
        <select
          className="w-full sm:w-auto px-4 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
        >
          <option value="all">Todos los formularios</option>
          <option value="FO-OPE-056">FO-OPE-056</option>
          <option value="FO-OPE-057">FO-OPE-057</option>
          {/* Añadir más opciones según los tipos de formularios */}
        </select>
      </div>

      <div className="space-y-4 max-h-[60vh] overflow-y-auto shadow-2xl shad">
        {filteredReports.length > 0 ? (
          filteredReports.map(renderReportCard)
        ) : (
          <div className="py-4 text-center">No se encontraron reportes.</div>
        )}
      </div>

      <button
        onClick={handleSync}
        className={`w-full py-3 text-lg font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4 px-4 text-white ${
          isOnline ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-600 cursor-not-allowed"
        }`}
        disabled={!isOnline}
      >
        {isOnline ? "Sincronizar Reportes" : "Sincronización no disponible"}
      </button>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <p>¿Estás seguro de que deseas eliminar este reporte?</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => {
                  handleDelete(reportToDelete.id);
                  setShowModal(false);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Eliminar
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      <AlertModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        report={selectedReport}
      />
    </div>
  );
}

export default ReportTable;