export const formatDate = (date) => {
    const pad = (n) => n.toString().padStart(2, '0');
    
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Los meses en JavaScript van de 0 a 11
    const year = date.getFullYear().toString().substr(-2); // Obtenemos los últimos 2 dígitos del año
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds()); // Añadimos los segundos

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};