import React, { useState } from "react";
import { FiTrash, FiEye } from "react-icons/fi";
import FullScreenPDFModal from "./FullScreenPDFModal";

const truncateText = (text, maxLength) => {
  if (!text) return "";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

const DailyActivitiesList = ({
  activities,
  onEditActivity,
  onDeleteActivity,
  onSyncActivity,
  usersios, 
  maquineries
}) => {
  const [selectedActivity, setSelectedActivity] = useState(null);

  const confirmDelete = (activity) => {
    if (
      window.confirm("¿Estás seguro de que quieres eliminar esta actividad?")
    ) {
      onDeleteActivity(activity.id);
    }
  };

  const getMachineryName = (id) => {
    const machinery = maquineries.find(m => m.id === Number(id));
    if (!machinery) {
      console.warn(`Maquinaria con ID ${id} no encontrada.`);
    }
    return machinery ? machinery.nombre : "Desconocido";
  };
  
  const getUserName = (id) => {
    const user = usersios.find(u => u.id === Number(id));
    if (!user) {
      console.warn(`Usuario con ID ${id} no encontrado.`);
    }
    return user ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}` : "Desconocido";
  };

  const renderActivityCard = (activity) => (
    <div
      key={activity.id}
      className="p-4 bg-white rounded-lg shadow-xl drop-shadow-xl flex items-center mb-4"
    >
      <div className="flex-1 flex flex-col justify-between">
        <div>
          <h3 className="text-sm font-semibold">
            {truncateText(getMachineryName(activity.machineryId), 30)}
          </h3>
          <p className="text-xs text-gray-400">{activity.date}</p>
          <p className="text-xs text-gray-400">Operador: {getUserName(activity.operatorId)}</p>
          <p className="text-xs text-gray-400">
            Asistentes: {activity.assistants.map(getUserName).join(", ")}
          </p>
        </div>
        
        <div className="flex justify-between items-center mt-2">
          <div className="flex space-x-2 items-start">
            <button
              onClick={() => confirmDelete(activity)}
              className="text-red-500 hover:text-red-700"
            >
              <FiTrash className="h-5 w-5" />
            </button>
            <button
              onClick={() => onEditActivity(activity)}
              className="text-green-500 hover:text-green-700"
            >
              <FiEye className="h-5 w-5" />
            </button>         
          </div>
            <div className={`w-3 h-3 rounded-full ${activity.synced ? "bg-green-500" : "bg-red-500"}`}></div>  
        </div>
        <div className="flex space-x-2 items-center mt-1 self-start">
          {!activity.synced && (
            <span className="text-yellow-500 text-xs">No sincronizado</span>
          )}
          {activity.synced && activity.approvalStatus === "pending" && (
            <span className="text-green-500 text-xs">
              Enviado Satisfactoriamente
            </span>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Actividades Diarias</h2>
      {activities.length > 0 ? (
        activities.map(renderActivityCard)
      ) : (
        <p>No hay actividades registradas.</p>
      )}
      {selectedActivity && (
        <FullScreenPDFModal
          activity={{
            ...selectedActivity,
            machineryName: getMachineryName(selectedActivity.machineryId),
            operator: getUserName(selectedActivity.operatorId),
            assistants: selectedActivity.assistants.map(getUserName).join(", "),
          }}
          onClose={() => setSelectedActivity(null)}
        />
      )}
    </div>
  );
};

export default DailyActivitiesList;