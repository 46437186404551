import React, { useState } from 'react';
import GenericForm from '../ayuda/GenericForm';
import { saveReportOffline } from "../../idb";
import AlertModal from '../ayuda/AlertModal';
import ErrorModal from '../ayuda/ErrorModal';
import {formatDate} from '../ayuda/formatDate'


const MaquinariaInspectionForm = ({ onReportSaved, title, maquineries }) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [filteredMaquineries, setFilteredMaquineries] = useState([]);
    const [searchCode, setSearchCode] = useState('');
    const [searchName, setSearchName] = useState('');
    const [initialFormData, setInitialFormData] = useState({});

    const fields = [
        { name: 'fecha', type: 'date', label: 'Fecha:', required: true},
        { name: 'codigo', type: 'text', label: 'CODIGO', required: true},
        { name: 'equipo', type: 'text', label: 'EQUIPO', required: true},
        { name: 'modelo', type: 'text', label: 'MODELO', required: true},
        { name: 'n° de serie', type: 'text', label: 'N° DE SERIE', required: true},
        { name: 'tipo de combustible', type: 'text', label: 'TIPO DE COMBUSTIBLE:', required: true},
        { name: 'kilometraje/horometro', type: 'text', label: 'KILOMETRAJE/HOROMETRO'},
        { name: 'obra', type: 'text', label: 'OBRA:', required: true},
        { name: 'recorrido del repuesto', type: 'text', label: 'RECORRIDO DEL REPUESTO'},
        { name: 'reporte de falla', type: 'textarea', label: 'REPORTE DE FALLA', required: true},
        { name: 'causalidad', type: 'text', label: 'CAUSALIDAD', required: true},
        { name: 'descripcion de falla', type: 'textarea', label: 'DESCRIPCION DE FALLA', required: true },
        { name: 'accion correctiva', type: 'textarea', label: 'ACCION CORRECTIVA', required: true },
      ];

      const fieldLabels = fields.reduce((acc, field) => {
        acc[field.name] = field.label;
        return acc;
    }, {});

    const handleSearch = (value, field) => {
        if (value.length >= 3) {
            const filtered = maquineries.filter(maquinaria =>
                maquinaria[field] && maquinaria[field].toLowerCase().includes(value.toLowerCase())
            ).slice(0, 10);
            setFilteredMaquineries(filtered);
        } else {
            setFilteredMaquineries([]);
        }
    };

    const handleSelectMaquinaria = (maquinaria) => {
        const formData = {
            codigo: maquinaria.codigo || '',
            equipo: maquinaria.nombre || '',
            modelo: maquinaria.modelo || '',
            'n° de serie': maquinaria.serie || '',
            'tipo de combustible': maquinaria.tipoCombustible || ''
        };
        setInitialFormData(formData);
        setFilteredMaquineries([]);
        setSearchCode('');
        setSearchName('');
    };

      const handleSubmit = async (formData) => {
        try {
            const report = {
                ...formData,
                creacion: formatDate(new Date()),
                title: title,
                fieldLabels: fieldLabels
            };

            await saveReportOffline(report);
            onReportSaved();
            setIsSuccessModalOpen(true);
        } catch (error) {
            console.error('Error al guardar el reporte:', error);
            setErrorMessage('Hubo un error al guardar el reporte. Por favor, inténtelo de nuevo.');
            setIsErrorModalOpen(true);
        }
    };

    return (
        <>
            <div className="mb-6 space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                        type="text"
                        placeholder="Buscar código"
                        value={searchCode}
                        onChange={(e) => {
                            setSearchCode(e.target.value);
                            handleSearch(e.target.value, 'codigo');
                        }}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <input
                        type="text"
                        placeholder="Buscar nombre"
                        value={searchName}
                        onChange={(e) => {
                            setSearchName(e.target.value);
                            handleSearch(e.target.value, 'nombre');
                        }}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                </div>
                {filteredMaquineries.length > 0 && (
                    <ul className="mt-2 max-h-48 overflow-y-auto bg-white border border-gray-200 rounded-md shadow-sm">
                        {filteredMaquineries.map(maquinaria => (
                            <li 
                                key={maquinaria.id} 
                                onClick={() => handleSelectMaquinaria(maquinaria)}
                                className="px-4 py-2 hover:bg-gray-50 cursor-pointer border-b border-gray-100 last:border-b-0"
                            >
                                <span className="font-medium">{maquinaria.codigo}</span>
                                <span className="text-gray-600"> - {maquinaria.nombre}</span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <GenericForm 
                fields={fields} 
                onSubmit={handleSubmit} 
                title={title}
                initialFormData={initialFormData}
            />
            <AlertModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message="El reporte de inspección se ha guardado exitosamente."
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        </>
    );
};

export default MaquinariaInspectionForm;