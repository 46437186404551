// src/components/FullScreenPDFModal.js
import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import DailyActivityPDF from './DailyActivityPDF';
import { FiX } from 'react-icons/fi';

const FullScreenPDFModal = ({ activity, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full h-full p-4 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Vista previa del PDF</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FiX size={24} />
          </button>
        </div>
        <div className="flex-grow">
          <PDFViewer width="100%" height="100%">
            <DailyActivityPDF data={activity} />
          </PDFViewer>
        </div>
      </div>
    </div>
  );
};

export default FullScreenPDFModal;